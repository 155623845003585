<template>
  <div class="container" :id="id">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h3>{{ sobre.titulo }}</h3>
        <p>{{ sobre.texto }}</p>
      </div>
      <div class="col-12 col-lg-6">
        <img
          class="img-fluid"
          :src="require('@/assets/img'.concat(sobre.imagem))"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#sobre {
  padding-top: 40px;
  h3 {
    font-family: "Roboto";
    font-weight: 700;
    letter-spacing: 0px;
    color: #086ea8;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 44px;
  }
  p {
    font-family: "Open Sans";
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #000000;
    max-width: 621px;
  }
}
@media (min-width: 992px) {
  #sobre {
    padding-top: 160px;
  }
}
</style>
<script>
import { sobre } from "@/assets/data/sobre.json";
import getImage from "../helpers.js";
export default {
  mixins: [getImage],
  props: {
    id: String,
  },
  data() {
    return {
      sobre: sobre,
    };
  },
};
</script>
