<template>
  <div class="container" :id="id">
    <div class="row">
      <div class="col-12 services__box">
        <h3>Serviços</h3>
      </div>
    </div>
    <div class="row services">
      <div
        class="col-12 col-lg-4 service"
        v-for="(servico, index) in servicos"
        :key="index"
      >
        <img class="img-fluid" :src="getImage(servico.imagem)" :alt="servico.titulo" />
        <h3>{{ servico.titulo }}</h3>
        <p v-html="servico.texto"></p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#servicos {
  padding-top: 100px;
  padding-bottom: 160px;
  .services__box {
    h3 {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 40px;
      line-height: 44px;
      letter-spacing: 0px;
      color: #086ea8;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
  }
  .services {
    .service {
      img {
        margin-bottom: 27px;
      }
      h3 {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0px;
        color: #021b29;
        margin-bottom: 20px;
      }
      p {
        font-family: "Open Sans";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}
</style>
<script>
import { servicos } from "@/assets/data/servicos.json";
import getImage from "../helpers.js";
export default {
  mixins: [getImage],
  props: {
    id: String,
  },
  data() {
    return {
      servicos: servicos,
    };
  },
};
</script>
