<template>
  <b-navbar toggleable class="navbar navbar-expand-md sticky-top">
    <div class="container-fluid">
      <b-navbar-brand href="#">
        <img :src="getImage(menu.logo.imagem)" height="45" />
      </b-navbar-brand>
      <b-navbar-toggle target="navbar-toggle-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x"></b-icon>
          <b-icon v-else icon="list"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" class="justify-content-end" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            v-for="(item, key) in menu.links"
            :key="key"
            @click="
              scrollToElement({ block: 'start', behavior: 'smooth' }, item.link);
              active = item.link;
            "
            :class="{ active: active === item.link }"
            :href="item.link"
            >{{ item.nome }}</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>
<style lang="scss" scoped>
.navbar {
  .navbar-toggler {
    border-color: transparent;
  }
  background: #fff;
  min-height: initial;
  padding: 0;
  .navbar-nav {
    li {
      .nav-link {
        font-family: "Roboto";
        letter-spacing: 0px;
        color: #064c75;
        text-transform: uppercase;
        font-size: 18px;
        padding: 5px 0px;
      }
    }
    li.active {
      font-weight: bold;
    }
  }
}
@media (min-width: 992px) {
  .navbar {
    .navbar-nav {
      li {
        .nav-link {
          padding: 0 25px;
        }
      }
    }
  }
}
</style>
<script>
import { menu } from "@/assets/data/menu.json";
import getImage from "../helpers.js";
export default {
  name: "Header",
  mixins: [getImage],
  methods: {
    scrollToElement(options, target) {
      const el = document.querySelector(target);
      if (el) {
        el.scrollIntoView(options);
      }
    },
  },
  data: function () {
    return {
      menu,
      active: null,
    };
  },
};
</script>
