<template>
  <div class="container-fluid" :id="id">
    <div class="row">
      <div class="col-12 col-lg-3 map__details">
        <h3>Contato</h3>
        <p>Selecione uma das nossas unidades:</p>
        <div class="map__container">
          <div class="map__content" v-for="(estudio, index) in estudios" :key="index">
            <div class="map__border">
              <h4>Unidade {{ estudio.nome }}</h4>
              <div class="map__address">
                <p>{{ estudio.endereco }}</p>
                <p>{{ estudio.telefone }}</p>
                <p>{{ estudio.email }}</p>
                <a :href="`mailto:${estudio.email}`">Entre em contato</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#contato {
  .map__details {
    padding-top: 96px;
    h3 {
      font-family: "Roboto";
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 32px;
      letter-spacing: 0px;
      color: #086ea8;
      text-transform: uppercase;
    }
    p {
      text-align: left;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #000000;
    }
    .map__container {
      border: 1px solid #666666;
      padding-top: 20px;
      margin-bottom: 48px;
      .map__border {
        border-bottom: 1px solid #666;
        padding: 20px 16px;
        h4 {
          font-family: "Open Sans";
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          text-transform: uppercase;
          margin-bottom: 16px;
        }
        p {
          margin-bottom: 0px;
        }
        a {
          font-size: 20px;
          font-weight: bold;
          font-family: "Open Sans";
          color: #1f76a8;
          margin-top: 16px;
          margin-bottom: 20px;
          display: block;
        }
      }
    }
  }
  #map {
    height: 420px;
  }
  @media (min-width: 992px) {
    #map {
      height: calc(100vh - 160px);
    }
  }
}
</style>
<script>
import { estudios } from "@/assets/data/estudios.json";
import { Loader } from "@googlemaps/js-api-loader";
export default {
  props: {
    id: String,
  },
  data() {
    return {
      estudios: estudios,
      mapOptions: {
        center: {
          lat: -23.58766097960597,
          lng: -46.67094457295033,
        },
        zoom: 14,
      },
    };
  },
  mounted: function () {
    const loader = new Loader({
      apiKey: "AIzaSyAcEdGu8NDuBKc8liYbCJkNlIGFF2Rh-wE",
      version: "weekly",
      mapId: "7275930177134a06",
      libraries: ["places"],
    });
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(document.getElementById("map"), this.mapOptions);

        this.estudios.forEach((estudio) => {
          const icon = {
            url: require("@/assets/img/".concat(estudio.icon)),
            scaledSize: new google.maps.Size(50, 50),
          };

          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(estudio.coords.lat, estudio.coords.lng),
            icon: icon,
            map: map,
            title: "Unidade ".concat(estudio.nome),
          });
          const infowindow = new google.maps.InfoWindow({
            content: `<h3>Unidade ${estudio.nome}</h3><p>${estudio.endereco}</p><p>${estudio.telefone}</p><p>${estudio.email}</p>`,
          });
          marker.addListener("click", () => {
            console.log(infowindow);
            infowindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            });
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
