<template>
  <main>
    <CarouselHome />
    <div class="container-fluid bg-gray">
      <Sobre id="sobre" />
      <Servicos id="servicos" />
    </div>
    <div class="container-fluid bg-white">
      <Estudios id="estudios" />
    </div>
    <div class="container-fluid bg-gray">
      <Famosos id="famosos" />
    </div>
    <div class="container-fluid bg-white">
      <Mapa id="contato" />
    </div>
  </main>
</template>
<style lang="scss" scoped>
.bg-gray {
  background: #f9f9f9;
}
</style>
<script>
import CarouselHome from "@/components/CarouselHome";
import Sobre from "@/components/Sobre";
import Servicos from "@/components/Servicos";
import Estudios from "@/components/Estudios";
import Famosos from "@/components/Famosos";
import Mapa from "@/components/Mapa";
export default {
  name: "Main",
  components: {
    CarouselHome,
    Sobre,
    Servicos,
    Estudios,
    Famosos,
    Mapa
  },
};
</script>
