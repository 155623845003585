<template>
  <div class="container" :id="id">
    <div class="row text-lg-center intro__text">
      <h3>{{ famosos.titulo }}</h3>
      <p>
        {{ famosos.descricao }}
      </p>
    </div>
    <div class="box__gallery">
      <b-card-group columns>
        <b-card
          v-for="(foto, index) in fotos"
          @click="openModalOnPhoto(index)"
          :key="index"
          :data-title="foto.descricao.concat(' + ')"
          :img-src="require('@/assets/img/famosos/'.concat(foto.sm))"
          img-alt="Image"
          img-top
        >
        </b-card>
      </b-card-group>
      <b-modal size="xl" ref="gallery" centered hide-footer :title="`${slideToShow + 1}/${fotos.length}`">
        <b-carousel
          ref="carrossel-famosos"
          :interval="0"
          v-model="slideToShow"
          indicators
          controls
          no-animation
          label-next=""
          label-prev=""
          label-indicators
        >
          <b-carousel-slide
            v-for="(foto, index) in fotos"
            :key="index"
            :img-src="require('@/assets/img/famosos/'.concat(foto.xl))"
          >
          </b-carousel-slide>
        </b-carousel>
      </b-modal>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.carousel-inner {
  max-height: 80vh;
}
#famosos {
  padding-top: 96px;
  .intro__text {
    h3 {
      margin-bottom: 32px;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0px;
      color: #1f76a8;
      text-transform: uppercase;
    }
    p {
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #000000;
      text-align: left;
    }
  }

  .box__gallery {
    .card-columns {
      column-count: 1;
      padding: 30px 0px;
      .card {
        display: inline-block;
        margin-bottom: 30px;
        cursor: pointer;
        .card-body {
          display: none;
        }
        &:after {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -1px;
          left: -1px;
          border-radius: 4px;
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          color: transparent;
          background: #1f76a800;
          content: attr(data-title);
          transition: 1s all;
        }
        &:hover {
          &:after {
            color: #fff;
            background: #1f76a8b3;
            transform: scale(1);
          }
        }
        border-color: #1f76a8;
        .card-title {
          color: #1f76a8;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .box__gallery {
      .card-columns {
        column-count: 2;
      }
    }
  }
  @media (min-width: 992px) {
    .intro__text {
      p {
        text-align: center;
        max-width: 795px;
        margin: 0 auto;
        margin-bottom: 70px;
      }
    }
    .box__gallery {
      .card-columns {
        column-count: 4;
      }
    }
  }
}
</style>
<script>
import { famosos } from "@/assets/data/famosos.json";
export default {
  props: {
    id: String,
  },
  data() {
    return {
      famosos: famosos,
      fotos: famosos.fotos,
      slide: 1,
      slideToShow: 0,
      sliding: null,
    };
  },
  methods: {
    openModalOnPhoto(index) {
      this.$refs["gallery"].show();
      this.slideToShow = index
      console.log(this.$refs["gallery"],index)
    },
  },
};
</script>
