<template>
  <div class="container" :id="id">
    <div class="row align-items-center justify-content-center box__estudios">
      <div class="col-12 col-lg-4 box__select-estudio row flex-column">
        <div class="col-12 content__selection">
          <h3>ESTÚDIOS</h3>
          <p>
            Camarim exclusivo, copa, internet vivo fibra, internet dedicada, iluminação em
            grid, sala de reunião e estúdio com tratamento acústico.
          </p>
        </div>
        <div class="col-12 estudio__selection">
          <div class="row mt-auto">
            <div
              class="col-12 button__select d-flex align-items-center justify-content-start"
              :class="isActive(unidade.nome) ? 'active' : ''"
              v-for="(unidade, index) in estudios"
              :key="index"
              @click.prevent="setActive(unidade.nome)"
            >
              <img :src="getImage(unidade.icon)" alt="" />
              <p>Unidade {{ unidade.nome }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <div
          :class="isActive(unidade.nome) ? 'd-block' : 'd-none'"
          class="unidade"
          v-for="(unidade, index) in estudios"
          :key="index"
        >
          <b-carousel no-animation :interval="4000">
            <b-carousel-slide
              v-for="(foto, index) in unidade.fotos"
              :key="index"
              :img-src="getImage(foto)"
            ></b-carousel-slide>
          </b-carousel>
          <div class="info__estudio container-fluid">
            <div
              class="row align-items-start justify-content-start align-lg-items-center justify-lg-content-center"
            >
              <div class="col-12 col-lg-2 text-lg-center">
                <img :src="getImage(unidade.icon)" alt="" />
              </div>
              <div class="col-12 col-lg-10 d-flex flex-column">
                <h3>Unidade {{ unidade.nome }}</h3>
                <p>{{ unidade.descricao }}</p>
                <b-button-group>
                  <b-button
                    variant="primary"
                    class="w-50"
                    size="sm"
                    @click="$bvModal.show(`precos-${index}`)"
                    >Tabela de preços</b-button
                  >
                  <b-button
                    variant="primary"
                    class="w-50"
                    size="sm"
                    @click="$bvModal.show(`fotos-${index}`)"
                  >
                    Mais fotos</b-button
                  >
                </b-button-group>

                <b-modal size="lg" :id="`precos-${index}`" hide-footer hide-header>
                  <div class="row">
                    <div
                      v-for="(tabela, indexTabela) in unidade.tabelas"
                      :key="indexTabela"
                      :class="`${
                        unidade.tabelas.length % 2 === 0 ? 'col-lg-6 col-12' : 'col-12'
                      }`"
                    >
                      <b-card-group deck>
                        <b-card :header="tabela.nome">
                          <b-list-group>
                            <b-list-group-item
                              v-for="(valor, vIndex) in tabela.valores"
                              :key="vIndex"
                              class="d-flex justify-content-between align-items-center"
                              >{{ valor.descricao }}
                              <b-badge variant="success" pill>{{
                                valor.valor
                              }}</b-badge></b-list-group-item
                            >
                          </b-list-group>
                        </b-card>
                      </b-card-group>
                    </div>
                  </div>
                </b-modal>
                <b-modal size="xl" :id="`fotos-${index}`" hide-footer>
                  <b-carousel no-animation indicators :interval="4000">
                    <b-carousel-slide
                      v-for="(foto, index) in unidade.fotos"
                      :key="index"
                      :img-src="getImage(foto)"
                    ></b-carousel-slide>
                  </b-carousel>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.badge {
  background: #1f76a8 0% 0% no-repeat padding-box;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.box__estudios {
  padding-top: 160px;
  padding-bottom: 160px;
  .box__select-estudio {    
    position: relative;
    .content__selection {
      background: #ffffff;
      border: 1px solid #1f76a8;
      border-bottom: none;
      padding-left: 35px;
      margin-top: auto;
      min-height: 320px;
      h3 {
        margin-top: 65px;
        font-family: "Roboto";
        font-size: 40px;
        margin-bottom: 40px;
        letter-spacing: 0px;
        color: #1f76a8;
        text-transform: uppercase;
      }
      p {
        font-family: "Open Sans";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
    .estudio__selection {   
      background: #ffffff;
      border: 1px solid #1f76a8;
      border-top: none;
      .button__select {
        cursor: pointer;
        p {
          margin: 45px 0;
        }
        img {
          width: 40px;
          height: 40px;
          margin-left: 10px;
          margin-right: 16px;
        }
        &.active {
          img {
            filter: invert(1) brightness(100);
          }
          background: #1f76a8 0% 0% no-repeat padding-box;
          color: #ffffff;
        }
        background: #fff;
        font-family: "Roboto";
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        letter-spacing: 0px;
        color: #021b29;
        text-transform: uppercase;
        border-top: 1px solid #1f76a8;
      }
    }
  }
  .unidade {
    position: relative;
    .info__estudio {
      padding: 20px;
      width: 100%;
      background: #1f76a8c7 0% 0% no-repeat padding-box;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      img {
        width: 48px;
        height: 48px;
        filter: invert(1) brightness(100);
      }
      h3 {
        margin-top: 24px;
        color: #fff;
        font-family: "Open Sans";
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        font-family: "Open Sans";
        margin: 0;
        margin-bottom: 10px;
      }
      button {
        border: 1px solid #fff;
        background: transparent;
      }
      .badge {
        background: #1f76a8c7;
      }
    }
  }
  @media (min-width: 992px) {
    .box__select-estudio {
      left: 120px;
      z-index: 2;
    }
    .unidade {
      .info__estudio {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        min-height: 250px;
        h3 {
          margin-top: initial;
        }
        img {
          width: 100px;
          height: 100px;
        }

        max-width: 80%;
      }
    }
  }
}
</style>
<script>
import { estudios } from "@/assets/data/estudios.json";
import getImage from "../helpers.js";
export default {
  mixins: [getImage],
  props: {
    id: String,
  },
  data() {
    return {
      estudios: estudios,
      unidadeAtiva: estudios[0].nome,
    };
  },
  methods: {
    isActive(unidadeAtiva) {
      return this.unidadeAtiva === unidadeAtiva;
    },
    setActive(unidadeAtiva) {
      this.unidadeAtiva = unidadeAtiva;
    },
  },
};
</script>
