<template>
  <b-carousel
    id="carrossel-hero"
    v-model="slide"
    :interval="0"
    indicators
    no-animation
    label-next=""
    label-prev=""
    label-indicators
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <template>
      <b-carousel-slide
        v-for="(slide, index) in slides"
        :key="index"
        :class="slide.tipo"
        :caption="slide.captions ? slide.titulo : ''"
        :text="slide.captions ? slide.texto : ''"
        :img-blank="slide.tipo == 'video'"
        :img-src="slide.tipo != 'video' ? require('@/assets/img/'.concat(slide.url)) : ''"
      >
        <template #img>
          <b-embed
            v-if="slide.tipo == 'video'"
            type="iframe"
            aspect="4by3"
            :src="slide.url"
            allow="autoplay; fullscreen; picture-in-picture"
          ></b-embed>
        </template>
      </b-carousel-slide>
    </template>
  </b-carousel>
</template>
<style lang="scss">
#carrossel-hero {
  .carousel-item {
    &.video {
      iframe {
        width: 100%;
        height: calc(56.25vw);
      }
      &:before {
        content: none;
      }
    }
    img {
      object-fit: cover;
      max-height: calc(100vh - 100px);
      width: 100%;
    }

    &:before {
      position: absolute;
      content: "";
      background: #086ea8;
      background-blend-mode: multiply;
      opacity: 0.5;
      width: 100%;
      height: 100%;
    }
    .carousel-caption {
      right: initial;
      left: 5%;
      transform: translateY(50%);
      bottom: 50%;
      max-width: 100%;
      text-align: left;
      h3 {
        font-family: "Roboto";
        font-size: 26px;
        line-height: 1.5em;
        font-weight: 700;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
      }
      p {
        font-family: "Open Sans";
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0px;
      }
    }
  }
  @media (min-width: 992px) {
    .carousel-item {
      .carousel-caption {
        max-width: initial;
        h3 {
          font-size: 40px;
        }
        p {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
<script>
import { carrossel } from "@/assets/data/carrossel.json";
import getImage from "../helpers.js";
export default {
  mixins: [getImage],
  data() {
    return {
      slide: 0,
      sliding: null,
      slides: carrossel,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
